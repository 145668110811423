import React, { useRef, useState, useEffect, useCallback } from "react"
import BallotSnapshotSwiper from "./BallotSnapshotSwiper"
import styles from "./BallotShare.module.css"
import type Swiper from "swiper"

interface Props {
    ballotIds: string[]
    ballotDates: string[]
}

declare global {
    interface Window {
        customAlert: (message?: string, callback?: () => void) => void
        customConfirm2: (
            title?: string,
            message?: string,
            confirm_callback?: () => void,
            cancel_callback?: () => void
        ) => void
        displayShare: () => void
    }
}

export function BallotSharePopup({
    ballotIds,
    ballotDates,
}: Props): JSX.Element {
    const [activeImg, setActiveImg] = useState<HTMLImageElement | null>(null)
    const [imageFile, setImageFile] = useState<File | null>(null)
    const [selectedDate, setSelectedDate] = useState<string>(
        ballotDates[0] ?? ""
    )
    const [selectedBallotId, setSelectedBallotId] = useState<string>(
        ballotIds[0] ?? ""
    )

    const imageCache = useRef(new Map<string, File>())
    const [handleShareRef, setHandleShareRef] = useState<(() => void) | null>(
        null
    )

    const handleShareCallback = useCallback((fn: () => void) => {
        setHandleShareRef(() => fn)
    }, [])

    useEffect(() => {
        if (activeImg === null) return

        /**
         * Simple cached wrapper around fetch
         * @param url
         * @returns
         */
        async function getImageFile(url: string): Promise<File> {
            if (imageCache.current.has(url)) {
                return imageCache.current.get(url) as File
            }
            const res = await fetch(url)
            const blob = await res.blob()
            const file = new File([blob], "ballot.jpg", { type: blob.type })
            imageCache.current.set(url, file)
            return file
        }

        getImageFile(activeImg.src)
            .then((file) => {
                setImageFile(file)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [activeImg])

    function swiperToActiveImg(swiper: Swiper): HTMLImageElement | null {
        const slide = swiper.slides[swiper.activeIndex]
        if (slide === undefined) {
            console.warn("active swiper slide was undefined")
            return null
        }
        const img = slide?.querySelector("img")
        if (img === null) {
            return null
        }
        return img !== undefined ? img : null
    }

    // useEffect(() => {}, [activeImage])

    function onSwiper(swiper: Swiper): void {
        const url = swiperToActiveImg(swiper)
        setActiveImg(url)
    }

    function onSlideChange(swiper: Swiper): void {
        const url = swiperToActiveImg(swiper)
        setActiveImg(url)
    }

    function ShareButton(): JSX.Element {
        if (navigator.canShare === undefined) {
            console.warn("Can't share anything")
            return <></>
        }
        const shareData: ShareData = {
            title: "Virtual Ballot",
            text: "Check out virtual ballot on Ballot Builder!",
            // url: `https://ballotbuilder.com/pages/ballot?ballot-id=${ballotId}`,
            files: imageFile !== null ? [imageFile] : undefined,
        }
        if (!navigator.canShare(shareData)) {
            console.warn(shareData, "Can't share")
            return <></>
        }
        return (
            <a
                className={styles.snapshotBottomButton}
                onClick={() => {
                    void navigator.share(shareData)
                }}>
                <img src="/assets/share2.svg" alt="share" />
            </a>
        )
    }
    function DownloadButton({
        ballotDate,
    }: {
        ballotDate: string
    }): JSX.Element {
        return (
            <a
                className={styles.snapshotBottomButton}
                onClick={() => {
                    window.generatePDF(ballotDate)
                }}>
                <img src="/assets/download.svg" alt="Download" />
            </a>
        )
    }

    return (
        <div className={styles.ballotSharePopup}>
            <div
                style={{
                    display: "flex",
                    position: "absolute",
                    left: "10px",
                }}>
                <DownloadButton ballotDate={selectedDate} />
                {/* <ShareButton /> */}
            </div>
            <div className="r3-py-1">
                <img
                    src="/assets/LOGO_only.png"
                    alt="logo"
                    className="r3-mx-auto r3-mb-1 r3-h-[60px]"
                />
            </div>
            <h3
                className="blue"
                style={{
                    fontSize: "20px",
                    fontWeight: "700",
                }}>
                Share Your Ballot
            </h3>
            {ballotDates.length > 0 && (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "20px",
                        marginTop: "-20px",
                    }}>
                    <select
                        onChange={(event) => {
                            setSelectedDate(event.target.value)
                            const selectedIndex = ballotDates.indexOf(
                                event.target.value
                            )
                            setSelectedBallotId(ballotIds[selectedIndex])
                        }}
                        style={{
                            alignItems: "flex-start",
                            backgroundColor: "var(--secondary-white-100)",
                            borderRadius: "8px",
                            display: "flex",
                            // minHeight: "36px",
                            // height: "30px",
                            marginTop: "4px",
                            padding: "8px",
                            border: "1px solid var(--primary-dark-grey10)",
                            // color: "var(--primarydark-grey70)",
                            fontWeight: "400",
                            lineHeight: "24px",
                            // minWidth: "150px",
                            // appearance: "none",
                        }}>
                        {/* <option value="" disabled selected>
                            Select a ballot...
                        </option> */}
                        {ballotDates.map((date) => (
                            <option key={date} value={date}>
                                {date}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            <hr className="r3-border r3-h-[1px] r3-w-full r3-border-solid r3-my-6 r3-border-gray-300" />

            <span
                className="btn link clear delete"
                onClick={window.displayShare}>
                <img src="/assets/X.svg" />
            </span>
            <BallotShareButtonSuite
                ballotId={selectedBallotId}
                handleShare={handleShareCallback}
            />
            <BallotSnapshotSwiper
                ballotId={selectedBallotId}
                onSwiper={onSwiper}
                onSlideChange={onSlideChange}
            />
            <div
                style={{
                    width: "100%",
                    marginTop: "20px",
                    position: "relative",
                }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                    }}>
                    <p style={{ fontFamily: "Doppio One" }}>Ballot Snapshot</p>
                    <p
                        style={{
                            fontFamily: "Doppio One",
                            fontSize: "20px",
                            position: "absolute",
                            right: 0,
                            cursor: "pointer",
                        }}
                        onClick={() => handleShareRef?.()}>
                        Ok
                    </p>
                </div>
            </div>
        </div>
    )
}

export function CandidateSharePopup({
    candidateName,
    candidateId,
}: {
    candidateName: string
    candidateId: string
}): JSX.Element {
    // http://localhost:3000/pages/election-candidates/candidate.php?candidate_id=416493
    const [handleShareRef, setHandleShareRef] = useState<(() => void) | null>(
        null
    )

    const handleShareCallback = useCallback((fn: () => void) => {
        setHandleShareRef(() => fn)
    }, [])

    return (
        <div className={styles.ballotSharePopup}>
            <h3 className="blue">Share Candidate</h3>
            <a className="btn link clear delete" onClick={window.displayShare}>
                <img src="/assets/X.svg" />
            </a>
            <ShareButtonSuite
                emailSubject={`Check out my candidate ${candidateName}!`}
                shareText={`Check out ${candidateName} on Ballot Builder!`}
                objectText={`candidate "${candidateName}"`}
                shareUrl={buildUrl({
                    base: `${window.location.origin}/pages/election-candidates/candidate.php`,
                    // base: "https://ballotbuilder.com/pages/election-candidates/candidate.php",
                    params: { candidate_id: candidateId },
                })}
                onShareRef={handleShareCallback}
            />
            <p
                style={{
                    fontFamily: "Doppio One",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "end",
                    cursor: "pointer",
                    marginTop: "10px",
                    marginRight: "10px"
                }}
                onClick={() => handleShareRef?.()}>
                Ok
            </p>
        </div>
    )
}

export function VideoSharePopup({
    url,
}: {
    url: string
}): JSX.Element {
    // http://localhost:3000/pages/election-candidates/candidate.php?candidate_id=416493
    const [handleShareRef, setHandleShareRef] = useState<(() => void) | null>(
        null
    )

    const handleShareCallback = useCallback((fn: () => void) => {
        setHandleShareRef(() => fn)
    }, [])

    return (
        <div className={styles.ballotSharePopup}>
            <h3 className="blue">Share The Video</h3>
            <a className="btn link clear delete" onClick={window.displayShare}>
                <img src="/assets/X.svg" />
            </a>
            <ShareButtonSuite
                emailSubject={`Checkout virtual ballot!`}
                shareText={`Check out the video from Ballot Builder`}
                objectText="this video"
                shareUrl={url}
                onShareRef={handleShareCallback}
            />
            <p
                style={{
                    fontFamily: "Doppio One",
                    fontSize: "20px",
                    display: "flex",
                    justifyContent: "end",
                    cursor: "pointer",
                    marginTop: "10px",
                    marginRight: "10px"
                }}
                onClick={() => handleShareRef?.()}>
                Ok
            </p>
        </div>
    )
}

function BallotShareButtonSuite({
    ballotId,
    handleShare,
}: {
    ballotId: string
    handleShare: (fn: () => void) => void
}): JSX.Element {
    return (
        <ShareButtonSuite
            emailSubject="Check out virtual ballot!"
            shareText="Check out virtual ballot on Ballot Builder!"
            objectText="your virtual ballot"
            shareUrl={ballotUrl(ballotId)}
            onShareRef={handleShare}
        />
    )
}

type SharePlatform = {
    type: "facebook" | "linkedin" | "twitter" | "truthsocial"
    url: string
    text?: string
    subject?: string
    body?: string
}

function ShareButtonSuite({
    emailSubject,
    shareText,
    objectText,
    shareUrl,
    onShareRef,
}: {
    emailSubject: string
    shareText: string
    objectText: string
    shareUrl: string
    onShareRef?: (handler: () => void) => void
}): JSX.Element {
    const [selectedPlatforms, setSelectedPlatforms] = useState<SharePlatform[]>(
        []
    )

    const togglePlatform = (platform: SharePlatform) => {
        setSelectedPlatforms((prev) => {
            const exists = prev.find((p) => p.type === platform.type)
            if (exists) {
                return prev.filter((p) => p.type !== platform.type)
            }
            return [...prev, platform]
        })
    }

    const platformsRef = useRef(selectedPlatforms)
    platformsRef.current = selectedPlatforms

    const sharePlatform = () => {
        console.log("Starting share process:", platformsRef.current)
        if (platformsRef.current.length === 0) {
            window.displayShare()
            return
        }

        const platform = platformsRef.current[0]
        const url = getPlatformUrl(platform)
        window.open(url, "_blank")

        if (platformsRef.current.length > 1) {
            window.customConfirm2(
                "Continue Sharing?",
                `Would you like to share ${objectText} to ${
                    platformsRef.current[1].type.charAt(0).toUpperCase() +
                    platformsRef.current[1].type.slice(1)
                }?`,
                () => {
                    platformsRef.current = platformsRef.current.slice(1)
                    sharePlatform()
                },
                () => {
                    window.displayShare()
                    setSelectedPlatforms([])
                }
            )
        } else {
            window.displayShare()
            setSelectedPlatforms([])
        }
    }

    useEffect(() => {
        if (onShareRef) {
            onShareRef(() => {
                sharePlatform()
            })
        }
    }, [onShareRef])

    function getPlatformUrl(platform: SharePlatform): string {
        switch (platform.type) {
            case "facebook":
                return facebookShareURL({ shareUrl: platform.url })
            case "linkedin":
                return linkedInShareURL({ shareUrl: platform.url })
            case "twitter":
                return twitterShareURL({
                    text: platform.text!,
                    url: platform.url,
                })
            case "truthsocial":
                return truthSocialShareURL({
                    text: platform.text!,
                    url: platform.url,
                })
            default:
                return ""
        }
    }

    return (
        // <div className="r3-grid r3-grid-cols-3 r3-gap-3 r3-w-full r3-pb-4">
        //     <FacebookShareButton shareUrl={shareUrl} />
        //     <LinkedInShareButton shareUrl={shareUrl} />
        //     <EmailShareButton
        //         subject={emailSubject}
        //         body={`${shareText} ${shareUrl}`}
        //     />
        //     <TwitterShareButton shareText={shareText} shareUrl={shareUrl} />
        //     <TruthSocialShareButton shareText={shareText} shareUrl={shareUrl} />
        //     <CopyLinkButton textToCopy={shareUrl} />
        // </div>

        <div className="r3-grid r3-grid-cols-3 r3-gap-3 r3-w-full r3-pb-4">
            <ShareButton
                type="facebook"
                icon="/assets/fb.svg"
                label="Facebook"
                isSelected={selectedPlatforms.some(
                    (p) => p.type === "facebook"
                )}
                onClick={() =>
                    togglePlatform({ type: "facebook", url: shareUrl })
                }
            />
            <ShareButton
                type="linkedin"
                icon="/assets/linkedin.svg"
                label="LinkedIn"
                isSelected={selectedPlatforms.some(
                    (p) => p.type === "linkedin"
                )}
                onClick={() =>
                    togglePlatform({ type: "linkedin", url: shareUrl })
                }
            />
            <EmailShareButton
                subject={emailSubject}
                body={`${shareText} ${shareUrl}`}
            />
            <ShareButton
                type="twitter"
                icon="/assets/xx.svg"
                label="X"
                isSelected={selectedPlatforms.some((p) => p.type === "twitter")}
                onClick={() =>
                    togglePlatform({
                        type: "twitter",
                        url: shareUrl,
                        text: shareText,
                    })
                }
            />
            <ShareButton
                type="truthsocial"
                icon="/assets/truth.svg"
                label="Truth Social"
                isSelected={selectedPlatforms.some(
                    (p) => p.type === "truthsocial"
                )}
                onClick={() =>
                    togglePlatform({
                        type: "truthsocial",
                        url: shareUrl,
                        text: shareText,
                    })
                }
            />
            <CopyLinkButton textToCopy={shareUrl} />
        </div>
    )
}

function ShareButton({
    type,
    icon,
    label,
    isSelected,
    onClick,
}: {
    type: string
    icon: string
    label: string
    isSelected: boolean
    onClick: () => void
}): JSX.Element {
    return (
        <a
            className={`${styles.shareButton} ${
                isSelected ? styles.selected : ""
            }`}
            onClick={onClick}>
            <img src={icon} alt={label} />
            <span>
                {label}
                {isSelected}
            </span>
        </a>
    )
}

function fallbackCopyTextToClipboard(text): void {
    const textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        const successful = document.execCommand("copy")
        const msg = successful ? "successful" : "unsuccessful"
        console.log("Fallback: Copying text command was " + msg)
    } catch (err) {
        console.error("Fallback: Oops, unable to copy", err)
    }

    document.body.removeChild(textArea)
}

function copyTextToClipboard(text: string): void {
    if (navigator.clipboard === undefined || navigator.clipboard === null) {
        fallbackCopyTextToClipboard(text)
        return
    }
    navigator.clipboard.writeText(text).then(
        function () {
            console.log("Async: Copying to clipboard was successful!")
            window.customAlert("Link is copied to clipboard")
        },
        function (err) {
            console.error("Async: Could not copy text: ", err)
        }
    )
}

function ballotUrl(ballotId: string): string {
    // return encodeURIComponent(
    //     `https://ballotbuilder.com/pages/ballot?ballot-id=${ballotId}`
    // )
    return buildUrl({
        base: `${window.location.origin}/pages/ballot/`,
        // base: "https://ballotbuilder.com/pages/ballot/",
        params: { "ballot-id": ballotId },
    })
}

function buildUrl({
    base,
    params,
}: {
    base: string
    params: Record<string, string>
}): string {
    const url = new URL(base)
    url.search = new URLSearchParams(params).toString()
    return url.href
}

/// Share URL spec

function twitterShareURL({ text, url }: { text: string; url: string }): string {
    return buildUrl({
        base: "http://x.com/share",
        params: { text, url },
    })
}

function linkedInShareURL({ shareUrl }: { shareUrl: string }): string {
    return buildUrl({
        base: "https://linkedin.com/sharing/share-offsite/",
        params: { url: shareUrl },
    })
}

function facebookShareURL({ shareUrl }: { shareUrl: string }): string {
    return buildUrl({
        base: "https://facebook.com/sharer/sharer.php",
        params: { u: shareUrl },
    })
}

// https://truthsocial.com/share?text=Check out my ballot on Ballot Builder!&url=https://ballotbuilder.com/pages/ballot?ballot-id=${ballotId}
function truthSocialShareURL({
    text,
    url,
}: {
    text: string
    url: string
}): string {
    return buildUrl({
        base: "https://truthsocial.com/share",
        params: { text, url },
    })
}

// href={`mailto:?subject=Checkout my ballot!&body=Check out my ballot on Ballot Builder! https://ballotbuilder.com/pages/ballot?ballot-id=${ballotId}`}>

function emailShareURL({
    subject,
    body,
}: {
    subject: string
    body: string
}): string {
    return buildUrl({
        base: "mailto:",
        params: { subject, body },
    })
}

/// Share buttons

function TwitterShareButton({
    shareText,
    shareUrl,
}: {
    shareText: string
    shareUrl: string
}): JSX.Element {
    const href = twitterShareURL({ text: shareText, url: shareUrl })

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (window.flutter_inappwebview) {
            e.preventDefault()
            window.flutter_inappwebview.callHandler("shareMobile", href)
        }
    }

    return (
        <a
            className={styles.shareButton}
            target="_blank"
            href={href}
            rel="noreferrer"
            onClick={handleClick}>
            <img src="/assets/xx.svg" alt="Twitter" />
            <span>X</span>
        </a>
    )
}

function LinkedInShareButton({ shareUrl }: { shareUrl: string }): JSX.Element {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (window.flutter_inappwebview) {
            e.preventDefault()
            window.flutter_inappwebview.callHandler("shareMobile", shareUrl)
        }
    }
    return (
        <a
            target="_blank"
            href={linkedInShareURL({ shareUrl })}
            className={styles.shareButton}
            onClick={handleClick}
            rel="noreferrer">
            <img src="/assets/linkedin.svg" alt="Linkedin" />
            <span>Linkedin</span>
        </a>
    )
}

function TruthSocialShareButton({
    shareText,
    shareUrl,
}: {
    shareText: string
    shareUrl: string
}): JSX.Element {
    const href = truthSocialShareURL({ text: shareText, url: shareUrl })
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (window.flutter_inappwebview) {
            e.preventDefault()
            window.flutter_inappwebview.callHandler("shareMobile", href)
        }
    }
    return (
        <a
            className={styles.shareButton}
            target="_blank"
            href={href}
            rel="noreferrer"
            onClick={handleClick}>
            <img src="/assets/truth.svg" alt="Truth Social" />
            <span>Truth Social</span>
        </a>
    )
}

function FacebookShareButton({ shareUrl }: { shareUrl: string }): JSX.Element {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (window.flutter_inappwebview) {
            e.preventDefault()
            window.flutter_inappwebview.callHandler("shareMobile", shareUrl)
        }
    }
    return (
        <a
            className={styles.shareButton}
            target="_blank"
            href={facebookShareURL({ shareUrl })}
            rel="noreferrer"
            onClick={handleClick}>
            <img src="/assets/fb.svg" alt="Facebook" />
            <span>Facebook</span>
        </a>
    )
}

function EmailShareButton({
    subject,
    body,
}: {
    subject: string
    body: string
}): JSX.Element {
    return (
        <a
            className={styles.shareButton}
            href={emailShareURL({ subject, body })}>
            <img src="/assets/mail.svg" alt="Email" />
            <span>Email</span>
        </a>
    )
}

function CopyLinkButton({ textToCopy }: { textToCopy: string }): JSX.Element {
    return (
        <a
            className={styles.shareButton}
            onClick={() => {
                copyTextToClipboard(textToCopy)
            }}>
            <img src="/assets/copy.svg" alt="Copy Link" />
            <span>Copy Link</span>
        </a>
    )
}
