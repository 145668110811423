.ballotSharePopup {
    margin-top: 10px;
    --light: #f9f9fb;
}

.ballotSharePopup p {
    font-weight: unset;
    font-family: "Doppio One";
}

.snapshotBottomButton {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 40px !important;
    height: 40px !important;
    /* height: 50px !important; */
    margin-left: 10px !important;
    margin-top: 10px;
    /*background-color: var(--light);*/
    padding-top: 9px;
    padding-bottom: 9px;
    border-radius: 8px;
    border: 0.5px solid #292F39;
    cursor: pointer;
}

.shareButton {
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #f0f0f0;
    padding: 8px 8px;
    border-radius: 8px;
    width: 100%;
    text-decoration: none;
    transition: background-color 0.2s;
    transition: all 0.2s;
}

.shareButton:hover {
    background-color: #e5e5e5;
}

.shareButton img {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.shareButton span {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    white-space: nowrap; 
}

/* .shareButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
} */

.shareButton.selected {
    background-color: var(--primarydark-grey100);
    color: white;
}

.shareButton.selected span {
    color: white;
}

.shareButton.selected img {
    /* Convert black to white */
    filter: brightness(0) invert(1);
}

@media (max-width: 550px) {
    .shareButton {
        padding: 6px 6px;
        gap: 8px
    }
    
    .shareButton span {
        font-size: 12px;
    }
    
    .shareButton img {
        width: 20px;
        height: 20px;
    }
}

.ballotSharePopup button {
    background-color: #F9F9FB !important;
}

/* .snapshotBottomButton > img {
    width: 30px;
    height: 30px;
} */